import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../styles/Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [arithmeticQuestion, setArithmeticQuestion] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [userAnswer, setUserAnswer] = useState("");

  useEffect(() => {
    // Generate a random arithmetic question
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    setArithmeticQuestion(`${num1} + ${num2}`);
    setCorrectAnswer(num1 + num2);
  }, []);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (!username || !password) {
      toast.error("Please enter both username and password!");
      return;
    }

    if (!userAnswer) {
      toast.error("Please answer the arithmetic question!");
      return;
    }

    if (parseInt(userAnswer, 10) !== correctAnswer) {
      toast.error("Incorrect arithmetic answer. Please try again.");
      return;
    }

    // Proceed to the next screen if validation passes
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/overview");
    }, 1000);
  };

  const handleRegister = () => {
    navigate("/register");
  };

  return (
    <div className="loginDiv1">
      <ToastContainer />
      <div className="loginDiv2">
        <div className="loginDiv2b">
          <div className="loginDiv21">
            <h1>BRAINSCLUB LOGIN</h1>
          </div>
          <div className="loginDiv22">
            <h3>USERNAME</h3>
            <div className="password-input-container">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <h3>PASSWORD</h3>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                onClick={handlePasswordToggle}
                className="password-toggle-icon"
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>

            <h3>Solve the Arithmetic Question</h3>
            <div style={{ display: "flex" }}>
              <p style={{ width: "10%" }}>
                <strong>{arithmeticQuestion}</strong>
              </p>
              <input
                type="number"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                placeholder="Your answer"
              />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="loginBtn"
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? <h3>Authenticating...</h3> : <h3>Login</h3>}
              </button>
              <button className="registerBtn" onClick={handleRegister}>
                <h3>Register</h3>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
