import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../styles/DashboardLayout.css"; // Link to CSS file
import { BiLogOut } from "react-icons/bi";
import { BiBox } from "react-icons/bi";

export default function DashboardLayout({ children }) {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const updateCurrentTime = () => setCurrentTime(new Date());
    const timer = setInterval(updateCurrentTime, 60000); // Update every minute
    return () => clearInterval(timer);
  }, []);

  const links = [
    { path: "/overview", label: "News", color: "#8a9494" },
    { path: "/dumps", label: "Dumps", color: "#90cfe4" },
    { path: "/cvv", label: "CVV2", color: "#90e4c2" },
    { label: "Fullz", color: "#b0e4d0" },
    { label: "Wholesale" },
    { path: "/cart", label: "Cart" },
    { path: "/orders", label: "Orders" },
    { label: "Auction", color: "#d7422e", color2: "white" },
    { path: "/profile", label: "Tools" },
    { path: "/ticket", label: "Ticket" },
  ];

  return (
    <div className="dashboard-layout">
      {/* Greeting and Date Section */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="greeting-section">
          <p className="greeting">Welcome!</p>
          <p className="time">
            <strong>Balance: </strong>
            0.00 USD [{" "}
            <span
              onClick={() => navigate("/deposit")}
              style={{
                textDecoration: "underline",
                color: "blue",
              }}
            >
              {" "}
              add funds{" "}
            </span>
            ]
          </p>
          <p className="time">
            <strong>Carb Rating: 🦀 </strong>[
            <span
              // onClick={() => navigate("/deposit")}
              style={{
                textDecoration: "underline",
                color: "blue",
              }}
            >
              {" "}
              details{" "}
            </span>
            ]
          </p>
          <p className="time">
            <strong>Current server time: </strong>
            {moment(currentTime).format("hh:mm:ss A")}
          </p>
        </div>

        <div
          style={{
            marginTop: 15,
            width: "30%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <button
            className="nav-button"
            style={{ color: "green" }}
            // onClick={() => navigate(link.path)}
          >
            Lottery $
          </button>
          <button
            className="nav-button"
            // onClick={() => navigate(link.path)}
          >
            <BiBox style={{ fontSize: 16, color: "red" }} />
            My Gift
          </button>
          <button
            className="nav-button"
            // onClick={() => navigate(link.path)}
            style={{ alignItems: "center" }}
          >
            <BiLogOut style={{ fontSize: 16 }} />
            Logout
          </button>
        </div>
      </div>
      <div className="dashboard-buttons-container">
        {/* Display links as buttons */}
        <nav className="button-links">
          {links.map((link) => (
            <button
              key={link.path}
              className={`nav-button ${
                currentPath === link.path ? "active" : ""
              }`}
              style={{ backgroundColor: link.color, color: link.color2 }}
              onClick={() => navigate(link.path)}
            >
              {link.label}
            </button>
          ))}
        </nav>
      </div>

      {/* Account Inactive Message */}
      <div className="inactive-account-message">
        <p>
          Your account is inactive. For activation you need to top up your
          balance.
        </p>
        <p>
          Attention: Not activated accounts for more than 5 days will be deleted
          automatically.
        </p>
        {/* <button className="deposit-button" onClick={() => navigate("/deposit")}>
          Deposit
        </button> */}
      </div>

      {/* Main content */}
      <main className="main-content">{children}</main>
    </div>
  );
}
