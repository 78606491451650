import React, { useState, useEffect } from "react";
import DashboardLayout from "../components/DashboardLayout";
import { FaBitcoin, FaEthereum, FaDashcube } from "react-icons/fa";
import { SiTether, SiLitecoin } from "react-icons/si";
import QRCode from "react-qr-code";
import "../styles/Deposit.css"; // Ensure styles are updated to reflect design changes

const Deposit = () => {
  const [selectedCoin, setSelectedCoin] = useState("");
  const [showQR, setShowQR] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const walletAddresses = {
    bitcoin: "bc1qfk46kuc74g2pn4n6qe3a95a07nwnc89htlaf2s",
    usdt: "TXzZJNDSyxvNyLZpLvcCnAZ3D1kuWXGAE3",
    litecoin: "ltc1q2w925mx3lmh3q5xfwhwga565gu4smv9m7xjw00",
    dash: "XpUH15JxKAbxDrkfXEENLUVvcyxLaCiHnM",
    eth: "0x72a71744926213D261d70De73b2203c39C26aE5E",
  };

  const coinIcons = {
    bitcoin: <FaBitcoin style={{ color: "orange", fontSize: 18 }} />,
    usdt: <SiTether style={{ color: "green", fontSize: 18 }} />,
    litecoin: <SiLitecoin style={{ color: "gray", fontSize: 18 }} />,
    dash: <FaDashcube style={{ color: "blue", fontSize: 18 }} />,
    eth: <FaEthereum style={{ color: "gray", fontSize: 18 }} />,
  };

  const handlePaymentClick = (coin) => {
    setSelectedCoin(coin);
    setWalletAddress(walletAddresses[coin]);
    setShowQR(false); // Hide QR temporarily for loading simulation
    setLoading(true);
    setTimeout(() => {
      setShowQR(true);
      setLoading(false);
    }, 1000);
  };

  return (
    <DashboardLayout>
      <div className="deposit-container">
        {/* Write-ups Section */}
        <section className="info-section">
          <ul>
            <li>
              Minimal amount: <strong>$50</strong>
            </li>
            <li>
              Receive <strong>10% bonus</strong> for every deposit over $500
            </li>
            <li>
              Processing fee: <strong>4%</strong>
            </li>
            <li>
              <strong>Always get a new address</strong> for each payment (1
              address = 1 transfer)
            </li>
          </ul>
        </section>

        {/* Deposit Methods Section */}
        <section className="methods-section">
          <div className="methods-grid">
            {Object.keys(walletAddresses).map((coin) => (
              <div className="method-card" key={coin}>
                {coinIcons[coin]}
                <h3>{coin.toUpperCase()}</h3>
                <button
                  className="payment-btn"
                  onClick={() => handlePaymentClick(coin)}
                >
                  Make Deposit {coin}
                </button>
              </div>
            ))}
          </div>
        </section>

        {/* QR Code Section */}
        {showQR && selectedCoin && (
          <section className="qr-section">
            <h2>
              {coinIcons[selectedCoin]} {selectedCoin.toUpperCase()} Wallet
              Address
            </h2>
            {selectedCoin == "usdt" && <h3>TRC20</h3>}
            <QRCode value={walletAddress} size={150} />
            <p className="wallet-address">{walletAddress}</p>
          </section>
        )}
        {loading && <p>Generating wallet address...</p>}

        {/* Unconfirmed Transaction Write-Up */}
        <section className="unconfirmed-info">
          <p>
            <strong>Unconfirmed Transactions:</strong> Bitcoin/Litecoin
            transactions await 2 confirmations on their respective networks.
            Confirmations can take
            <strong> 20 minutes to 1 day</strong> if sent without a fee.
          </p>
        </section>

        {/* Transaction History Section */}
        <section className="history-section">
          <h2>Transaction History</h2>
          <table className="history-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Coin</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="4" style={{ textAlign: "center" }}>
                  No payment history
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </DashboardLayout>
  );
};

export default Deposit;
